import {useEffect, useState} from "react";
import {useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import "./Account.css";
import {Loading} from "../components/Loading";
import config from "../assets/config.json";

export function Account() {
    const { authState, onLogout, dict } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [ email, setEmail ] = useState('');
    const navigate = useNavigate();

    const logout = async () => {
        await onLogout();
    }

    useEffect(() => {
        setIsLoading(true);

        fetch(config.server+'/account.php', {
            method: 'POST',
            body: JSON.stringify({token: authState.token})
        }).then(rsp => rsp.json()).then(data => {
            if(data.status === 'ok') {
                setEmail(data.email);
                setTimeout(() => setIsLoading(false), 200);
            } else {
                onLogout();
                navigate('/');
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{isLoading ?
        <Loading /> :
        <>
            <div className="AccountInfo">
                <div>{dict.email ?? "Email"}</div>
                <div>{email}</div>
            </div>
            <button className="AccountLogOut" onClick={() => logout()}>
                {dict.logout ?? "Log Out"}
            </button>
        </>
}   </>;
}