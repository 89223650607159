import React from 'react';
import ReactDOM from 'react-dom/client';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {AuthProvider} from "./contexts/AuthContext";
import {BrowserRouter} from "react-router-dom";

import CacheBuster from 'react-cache-buster';


import './index.css';
import App from './App';

import pkg from "../package.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProduction = process.env.NODE_ENV === 'production';
root.render(
    <CacheBuster
      currentVersion={pkg.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<div className='Loading'>Loading...</div>}
      metaFileDirectory={'.'}
    >
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
    </CacheBuster>
);

serviceWorkerRegistration.register();

reportWebVitals();
