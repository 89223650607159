import "./SelectInput.css";

export function SelectInput(props) {


    return <div className="SelectInputBase" style={{width: props.width ?? ''}}>
        <div className="Label">{props.label}</div>
        <select onInput={(e) => props.onInput(e.target.value)} type="text">
            {props.data.map(val => <option key={val.value} value={val.value}>{val.text}</option>)}
        </select>
    </div>;
}