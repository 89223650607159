import {Route, Routes} from "react-router";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

import {useAuth} from "./contexts/AuthContext";

import {Login} from "./pages/Login";
import {Home} from "./pages/Home";
import {Account} from "./pages/Account";
import {New} from "./pages/New";
import {InstallPWA} from "./components/InstallPWA";

import "./App.css";
import UserIcon from "./images/icon.png";
import HomeIcon from "./images/back.png";

import config from "./assets/config.json";

function Navbar(props) {
    const {authState} = useAuth();
    const [fullname, setFullname] = useState('');
    const [icon, setIcon] = useState(UserIcon);
    const [link, setLink] = useState('/account')

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(authState.token !== '') {
            if(fullname === '') {
                fetch(config.server+'/account.php', {
                    method: 'POST',
                    body: JSON.stringify({token: authState.token})
                }).then(r => r.json()).then(data => {
                    setFullname(data.fullname);
                });
            }

            if(location.pathname === '/account') {
                setIcon(HomeIcon);
                setLink('/');
            } else {
                setIcon(UserIcon);
                setLink('/account');
            }
        }
    }, [navigate]);

    return <div className="Base"  style={{position: 'fixed'}}>
        <div className="Header">
            <div className="Content">
                <div className="Logo" onClick={() => navigate(link)}>
                    <img src={icon} width="100%" height="100%"  alt="HeadImage"/>
                </div>
                <div className="Title">
                    {fullname}
                </div>
                <div className="Space" ></div>
            </div>
        </div>
        <div className="Body">
            <div className="Content">
                {props.children}
            </div>
        </div>
    </div>
}

export default function App() {
    const {authState} = useAuth();
    const [isPWA, setIsPWA] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsPWA(true);
        } else {
            setIsPWA(false);
        }

        if(!authState.auth) navigate('/');
    }, [authState.auth, navigate]);

    return <>{isMobile ? <>{isPWA ? (<>
        {authState.auth ? (<Navbar>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/account" element={<Account />} />
                <Route path="/new" element={<New />} />
            </Routes>
        </Navbar>) : (<Routes>
            <Route path="/" element={<Login />} />
        </Routes>)
    }</>) : (<div className="PWAInstall">
        <div>Dart Visit WebApp</div>
        <InstallPWA />
    </div>)}</> : <>
        <div className="GoToDart">
            <a href="http://www.projectdart.eu">Go to Project D.A.R.T.</a>
        </div>
    </>}</>;
}

