import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

import "./New.css";

import { SelectInput } from "../components/SelectInput";
import { DateInput } from "../components/DateInput";
import { TextInput } from "../components/TextInput";
import { Loading } from "../components/Loading";
import { SelectSearch } from "../components/SelectSearch";
import config from "../assets/config.json";
import CheckClient from "../components/CheckClient";

export function New() {
    const { authState, onLogout, dict } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [clientData, setClientData] = useState([]);
    const [pmData, setPmData] = useState([]);
    const [brandData, setBrandData] = useState([]);

    const [conto, setConto] = useState('');
    const [client, setClient] = useState('');
    const [date, setDate] = useState('');
    const [brand1, setBrand1] = useState('');
    const [brand2, setBrand2] = useState('')
    const [pm1, setPm1] = useState('');
    const [pm2, setPm2] = useState('');
    const [note, setNote] = useState('');

    const [showCheckClient, setShowCheckClient] = useState(false);

    const navigate = useNavigate();

    let now = new Date(Date.now());
    const nowYear = now.getFullYear();
    const nowMonth = (now.getMonth() < 9 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1));
    const nowDate = (now.getDate() < 10 ? '0' + now.getDate() : now.getDate())

    now = nowYear +
        '-' + nowMonth +
        '-' + nowDate;

    const loadClients = async () => {
        setIsLoading(true);

        await fetch(config.server + '/clienti.php', {
            method: 'POST',
            body: JSON.stringify({ token: authState.token })
        }).then(rsp => rsp.json()).then(data => {
            if (data.status === 'ok') {
                setClientData(data.data);
            } else {
                onLogout();
                navigate('/');
            }
        });

        await fetch(config.server + '/pm.php', {
            method: 'POST',
            body: JSON.stringify({ token: authState.token })
        }).then(rsp => rsp.json()).then(data => {
            if (data.status === 'ok') {
                setPmData([{ value: '', text: dict.select ?? 'Select...' }, ...data.data]);
            } else {
                onLogout();
                navigate('/');
            }
        });

        await fetch(config.server + '/brand.php', {
            method: 'POST',
            body: JSON.stringify({ token: authState.token })
        }).then(rsp => rsp.json()).then(data => {
            if (data.status === 'ok') {
                setBrandData([{ value: '', text: dict.select ?? 'Select...' }, ...data.data]);
            } else {
                onLogout();
                navigate('/');
            }
        });

        setDate(now);

        setTimeout(() => setIsLoading(false), 200);
    }

    useEffect(() => {
        if (!authState.auth) navigate('/');

        loadClients();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const create = () => {
        if (client === '') return alert(dict.select_client ?? 'Select client...');
        if (date === '') return alert(dict.select_data ?? 'Select data...');
        setIsLoading(true);
        fetch(config.server + '/create.php', {
            method: 'POST',
            body: JSON.stringify({
                token: authState.token,
                conto: conto,
                client: client,
                data: date,
                note: note,
                brand1: brand1,
                brand2: brand2,
                pm1: pm1,
                pm2: pm2
            })
        }).then(rsp => rsp.json()).then(data => {
            if (data.status === 'ok') {
                setTimeout(() => {
                    setIsLoading(false);
                    window.alert(dict.visit_was_created ?? 'Visit was created!');
                    navigate('/');
                }, 200);

            } else {
                onLogout();
                navigate('/');
            }
        })
    }

    return (
        <div className="NewBase">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <CheckClient
                        show={ showCheckClient }
                        onClose={ () => setShowCheckClient(false) }
                        token={ authState.token }
                    />
                    <div className="NewBody">
                        <button className="CheckClienButton" onClick={ () => setShowCheckClient(true) }>{dict?.check_client ?? "Check Cliente"}</button>
                        <SelectSearch
                            width="100%"
                            label={ dict.client ?? 'Client' }
                            items={
                                clientData.map((item) => {
                                    return {
                                        value: item.CONTO,
                                        text: `[${item.PARTITA_IVA}] ${item.RAGIONE_SOCIALE}`
                                    }
                                })
                            }
                            onInput={ (val, text) => {
                                setConto(val);
                                setClient(text);
                            } }
                            message={
                                conto ? (
                                    clientData.filter(item => item.CONTO === conto)[0]?.AGENTE && ("Agente " + clientData.filter(item => item.CONTO === conto)[0]?.AGENTE)
                                ) : client ? (
                                    "Nuovo cliente"
                                ) : (
                                    "Inserisci un cliente"
                                )
                            }
                        />
                        <DateInput value={ now } width="100%" label={ dict.data ?? 'Data' } onInput={ (val) => setDate(val) } />
                        <SelectInput width="100%" label={ dict.brand_1 ?? 'Brand 1' } onInput={ (val) => setBrand1(val) } data={ brandData } />
                        <SelectInput width="100%" label={ dict.brand_2 ?? 'Brand 2' } onInput={ (val) => setBrand2(val) } data={ brandData } />
                        <SelectInput width="100%" label={ dict.pm_1 ?? 'PM 1' } onInput={ (val) => setPm1(val) } data={ pmData } />
                        <SelectInput width="100%" label={ dict.pm_2 ?? 'PM 2' } onInput={ (val) => setPm2(val) } data={ pmData } />
                        <TextInput width="100%" label={ dict.note ?? 'Note' } onInput={ (val) => setNote(val) } />
                    </div>
                    <div className="NewFooter">
                        <div onClick={ () => navigate('/') } className="ButtonAnnulla">
                            { dict.decline ?? 'Decline' }
                        </div>
                        <div onClick={ create } className="ButtonCrea">
                            { dict.create ?? 'Create' }
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}