import './Login.css';
import Logo from '../images/logo.png';
import {useAuth} from "../contexts/AuthContext";
import {useEffect, useState} from "react";
import {TextInput} from "../components/TextInput";
import {useNavigate} from "react-router-dom";
import config from "../assets/config.json";

export function Login() {
    const {onLogin, authState} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if(authState.auth) navigate('/home')
    }, [authState.auth, navigate]);

    const login = async () => {
        let data = await onLogin(username, password);

        if(data.status !== 'ok') {
            alert(data.msg);
        }
    }

    return <div className="LoginBase">
        <svg className="WaveTop" xmlns="http://www.w3.org/2000/svg" width="430" height="166" viewBox="0 0 414 166" fill="none">
            <path d="M-50 -8C-50 -11.866 -46.866 -15 -43 -15H446C449.866 -15 453 -11.866 453 -8V146.637C453 151.484 448.193 154.864 443.632 153.225L354.272 121.108C321.171 109.211 284.667 111.198 253.054 126.617L233.984 135.918C143.497 180.052 36.5365 173.756 -48.1476 119.311V119.311C-49.3021 118.568 -50 117.29 -50 115.917V-8Z" fill="url(#paint0_linear_2_5)"/>
            <defs>
                <linearGradient id="paint0_linear_2_5" x1="200.992" y1="35.4132" x2="200.322" y2="198.9" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2152FF"/>
                    <stop offset="1" stopColor="#21D4FD"/>
                </linearGradient>
            </defs>
        </svg>
        <svg className="WaveBottom" xmlns="http://www.w3.org/2000/svg" width="430" height="116" viewBox="0 0 414 116" fill="none">
            <path d="M475.387 149.548C475.341 153.414 472.17 156.51 468.304 156.464L-16.5005 150.697C-20.3662 150.651 -23.4627 147.48 -23.4167 143.614L-21.9348 19.0406C-21.896 15.7832 -18.507 13.6526 -15.5547 15.0295L5.9479 25.0583C77.6525 58.5011 160.44 58.7002 232.304 25.6027V25.6027C308.667 -9.56647 397.077 -7.03307 471.3 32.4511L474.662 34.2395C475.942 34.9202 476.735 36.2579 476.718 37.7072L475.387 149.548Z" fill="url(#paint0_linear_14_115)"/>
            <defs>
                <linearGradient id="paint0_linear_14_115" x1="180.601" y1="108.528" x2="182.515" y2="-18.6602" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2152FF"/>
                    <stop offset="1" stopColor="#21D4FD"/>
                </linearGradient>
            </defs>
        </svg>
        <div className="Version">{config.version}</div>
        <div className="Box" style={{position: 'fixed'}}>
            <img className="Logo" width="330px" src={Logo} alt=""/>
            <h1 className="Title">Welcome</h1>
            <h3 className="SubTitle">Login to continue</h3>
            <TextInput label="Username" onInput={(val) => setUsername(val)} />
            <TextInput label="Password" type="password" onInput={(val) => setPassword(val)} />
            <div className="Footer">
                <div className="UnderText"></div>
                <div>
                    <button onClick={login}>Log In</button>
                </div>
            </div>
        </div>
    </div>;
}