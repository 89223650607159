import React, { useEffect, useState } from "react";
import icon from "../images/icon.png";

import "./InstallPWA.css";

export function InstallPWA() {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        });

        window.removeEventListener("transitionend", (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        });
    }, []);

    if (!supportsPWA) return <></>;

    return <div className="InstallPWAComp">
        <div className="Info">
            <img src={icon} height="56px" width="56px" alt="DV" />
            <div className="Description">
                <p className="Title">Dart Visit</p>
                <p className="SubTitle">Install application on your phone</p>
            </div>
        </div>
        <div className="Button">
            <button className="link-button" id="setup_button" aria-label="Install app" title="Install app" onClick={(evt) => {
                evt.preventDefault();
                if (!promptInstall) {
                    return;
                }
                promptInstall.prompt();
            }}>Install</button>
        </div>
    </div>;
}