
import PlusIcon from "../images/add.png"
import RefreshIcon from "../images/refresh.png";
import "./List.css";
import {useNavigate} from "react-router-dom";

export function List(props) {
    const navigate = useNavigate();
    let index = 0;
    const lang = props.lang ?? {
        title: 'Title',
        remove: 'Remove',
        missing: 'Missing'
    };

    return (
        <div className="ListBase">
            <div className="Header">
                <div className="Title">{lang.title}</div>
                <div className="Buttons">
                    <div onClick={() => navigate(props.link)}>
                        <img src={PlusIcon} alt="Plus" width={24} height={24} />
                    </div>
                    <div onClick={() => props.refresh()}>
                        <img src={RefreshIcon} alt="Refresh" width={24} height={24} />
                    </div>
                </div>
            </div>
            <div className="Body">
                {Object.keys(props.data).length > 0 ? Object.keys(props.data).map((key) => (
                    <div key={index++} className="Row">
                        {props.data[key].map((val) => (
                            <div key={val} className="Value">{val}</div>
                        ))}

                        <button className="Delete" onClick={() => props.remove(key)}>{lang.remove}</button>
                    </div>
                )) : (
                    <div className="Row">
                        <div className="Value">{lang.missing}</div>
                    </div>
                )}
            </div>
        </div>
    );
}