import {createContext, useState, useContext, useEffect} from "react";
import {useCookies} from "react-cookie";

import config from "../assets/config.json";

const AuthContext = createContext({
    authState: false,
    onLogin: () => {},
    onLogout: () => {},
    dict: {}
});

export const useAuth = () => {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [authState, setAuthState ] = useState({ token: '', auth: false });
    const [dict, setDict] = useState({});

    useEffect(() => {
        if(cookies.token !== undefined && cookies.token !== '') {
            setAuthState({token: cookies.token, auth: true});
        
            fetch(config.server + '/dictionary.php', {
                method: 'POST',
                body: JSON.stringify({
                    token: cookies.token
                })
            }).then(rsp => rsp.json()).then(data => {
                setDict(data.dict);
            });
        }
    }, [cookies, setAuthState]);

    const login = async (username, password) => {
        try {
            let data = await fetch(config.server + '/login.php', {
                method: 'POST',
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            }).then(rsp => rsp.json()).then(data => data);

            if(data.status === 'ok') {
                

                setCookie('token', data.token, {expires: new Date(Date.now() + (3600 * 1000 * 24 * 180))});
                setAuthState({ token: data.token, auth: true });

                let dictionary = await fetch(config.server + '/dictionary.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        token: data.token
                    })
                }).then(rsp => rsp.json()).then(data => data);

                setDict(dictionary.dict);

                return { status: 'ok' };
            } else {
                return { status: data.status, msg: data.msg };
            }
        } catch (err) {
            console.error(err)
            return { status: 'error', msg: err.message}
        }

    }

    const logout = async () => {
        await fetch(config.server + '/logout.php', {
            method: 'POST',
            body: JSON.stringify({ token: authState.token })
        });
        removeCookie('token');
        setAuthState({ token: '', auth: false });
    }

    const value = {
        onLogin: login,
        onLogout: logout,
        authState: authState,
        dict: dict
    }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}