import "./SelectSearch.css";
import { useEffect, useState } from "react";

export function SelectSearch({ onInput, label, width, items, message }) {
    const [active, setActive] = useState(false);
    const [data, setData] = useState({ query: '', value: '' });

    const filter = (items) => active ?(
        items.filter(item => item.text.toLowerCase().includes(data.query.toLowerCase()))
    ) : [];

    useEffect(() => {
        onInput(data.value, data.query);
    }, [data])

    return <div className="SelectSearchBase" style={ { width: width ?? '' } }>
        <div className="Label">{ label }</div>
        <SearchBar
            value={ data.query }
            onInput={ ({ query }) => setData({ query, value: '' })}
            onFocus={ () => setActive(true) }
            onBlur={ () => setTimeout(() => setActive(false), 50) }
        />
        <div className="Message">
            {message}
        </div>
        {
            active && (
                <Searcher
                    items={ filter(items) }
                    onSelect={ ({ query, value }) => {
                        setActive(false);
                        setData({ query, value });
                    } }
                />
            )
        }
    </div>;
}

function SearchBar({ onInput, onFocus, onBlur, value  }) {
    return (
        <input
            onInput={ (e) => { onInput({ query: e.target.value }) } }
            onFocus={ onFocus }
            onBlur={ onBlur }
            value={ value }
            type="text"
        />
    );
}

function Searcher({ onSelect, items }) {
    return (
        <>
            {items.length > 0 && (
                <div className="Data">
                    {
                        items.map((item, index) => (
                            <div
                                key={ index }
                                onClick={ () => onSelect({ query: item.text, value: item.value }) }
                            >
                                { item.text }
                            </div>
                        ))
                    }
                </div>
            )}
        </>
    );
}