import { useState } from "react";

import "./CheckClient.css";

import config from "../assets/config.json";
import { useAuth } from "../contexts/AuthContext";


export default function CheckClient({ token, show, onClose }) {
    //#region HOOKS
    const { dict } = useAuth();
    //#endregion

    //#region DATA
        const [query, setQuery] = useState('');
        const [free, setFree] = useState(null);
    //#endregion

    //#region LOGIC
        const onSubmit = async (e) => {
            await fetch(config.server + '/checkClient.php', {
                method: 'POST',
                body: JSON.stringify({ token: token, iva: query })
            }).then(rsp => rsp.json()).then(data => {
                if (data.status === 'ok') {
                    setFree(data.free);
                } else {
                    setFree(null);
                }
            });
        }
    //#endregion

    //#region RENDER
    return (
        <div className="CheckClientBase" style={{visibility: show ? 'visible' : 'hidden'}}>
            <div className="Box">
                <button className="CloseButton" onClick={onClose}>x</button>
                <div className="Header">{dict.check_client ?? "Check Client"}</div>
                <input value={query} onInput={(e) => setQuery(e.target.value)} placeholder={dict.vat ?? "VAT"} />
                <button className="Submit" onClick={onSubmit}>{dict.check_client ?? "Check Client"}</button>
                <div className="Answare">
                    {free === null ? (
                        dict.insert_vat ?? 'Insert VAT'
                    ) : free ? (
                        dict.client_not_registered ?? 'Client not registered'
                    ) : (
                        dict.client_is_assigned ?? 'Client is assigned'
                    )}
                </div>
            </div>
        </div>
    );
    //#endregion
}

//#region COMPONENTS
    
//#endregion

//#region CUSTOM HOOKS
    
//#endregion