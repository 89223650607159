import {useAuth} from "../contexts/AuthContext";
import {useEffect, useState} from "react";
import "./Home.css";
import {List} from "../components/List";
import {Loading} from "../components/Loading";
import config from "../assets/config.json";

export function Home() {
    const { authState, onLogout, dict} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [ listData, setListData ] = useState([[]]);

    const refresh = async () => {
        setIsLoading(true);

        await fetch(config.server+'/visits.php', {
            method: 'POST',
            body: JSON.stringify({token: authState.token})
        }).then(r => r.json()).then(data => {
            if(data.status === 'ok') {
                setListData(data.data)
                setTimeout(() => setIsLoading(false), 200);
            } else {
                console.error('Generic Error');
                onLogout();
            }
        }).catch(err => console.error(err));

    }

    const remove = (id) => {
        if(window.confirm(dict.delete_sure ?? 'Are you sure you want delete?')) {
            fetch(config.server+'/deleteVisit.php', {
                method: 'POST',
                body: JSON.stringify({token: authState.token, id: id})
            }).then(r => r.json()).then(data => {
                if(data.status === 'ok') {
                    refresh();
                } else {
                    console.error('Generic Error');
                }
            }).catch(err => console.error(err));
        }
    }

    useEffect(() => {
        refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{isLoading ?
        <Loading /> :
        <List lang={ { title: dict.my_visits, missing: dict.missing_visits, remove: dict.remove } }
              refresh={refresh} 
              remove={remove}
              link="/new"
              data={listData} />
    }</>;
}