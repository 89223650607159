import "./Loading.css";

export function Loading() {
    return <div style={{height: '100%', display: 'flex', justifyContent: 'start', flexDirection: 'column'}}>
        <div style={{display: 'flex', width: '100%'}}>
            <div className="Loading" style={{flex: '4', marginLeft: '5px'}}></div>
            <div style={{flex: '1', marginRight: '5px'}}></div>
        </div>
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{flex: '1', marginRight: '5px'}}></div>
            <div className="Loading" style={{flex: '3', marginLeft: '5px'}}></div>
        </div>
        <div style={{display: 'flex', width: '100%'}}>
            <div className="Loading" style={{flex: '5', marginRight: '5px'}}></div>
            <div className="Loading" style={{flex: '1', marginLeft: '5px'}}></div>
        </div>
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{flex: '1', marginLeft: '5px'}}></div>
            <div className="Loading" style={{flex: '5', marginRight: '5px'}}></div>
            <div style={{flex: '3', marginLeft: '5px'}}></div>
        </div>

        <div style={{display: 'flex', width: '100%'}}>
            <div style={{flex: '2', marginLeft: '5px'}}></div>
            <div className="Loading" style={{flex: '2', marginRight: '5px'}}></div>
            <div style={{flex: '2', marginLeft: '5px'}}></div>
        </div>
    </div>;
}